import React, { useRef, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import dataArchitecture from '../../data/Slider/architecture-data.json';
import Loader from './../../components/Loader/Loader';
import HeaderFive from '../../components/Header/HeaderFive';
import ArchitectureSlider from '../../components/Hero/ArchitectureSlider';
import AboutArchitecture from '../../components/AboutUs/AboutArchitecture';
import ServicesArchitecture from '../../components/OurServices/ServicesArchitecture';
import GalleryArchitecture from '../../components/Gallery/GalleryArchitecture';
import FooterOne from '../../components/Footer/FooterOne';
import dataNav from '../../data/Navbar/nav-construction-data.json';

const Architecture = () => {
    const home = useRef();
    const about = useRef();
    const service = useRef();
    const clients = useRef();

    const scrollToSection = (e, content) => {
        e.preventDefault();
        switch (content) {
            case 'home':
                home.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'about':
                about.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'service':
                service.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'clients':
                clients.current.scrollIntoView({ behavior: 'smooth' });
                break;
            default:
        }
    };

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <Loader>
            <HeaderFive data={dataNav} scrollToSection={scrollToSection} />
            <ArchitectureSlider data={dataArchitecture} ref={home} />
            <AboutArchitecture ref={about} />
            <ServicesArchitecture
                title='NUESTROS SERVICIOS'
                tagline='¿Qué ofrecemos?'
                ref={service}
            />
            <GalleryArchitecture
                title='NUESTROS CLIENTES'
                tagline='Y contando'
                ref={clients}
            />
            <FooterOne />
        </Loader>
    );
};

export default Architecture;
