import React, { useRef } from 'react';
import Loader from './../../components/Loader/Loader';
import dataCreative from '../../data/Slider/creative-portfolio-data.json';
import dataPortfolio from '../../data/Portfolio/portfolio-masonry.json';
import HeaderFive from '../../components/Header/HeaderFive';
import HeroSliderCreative from '../../components/Hero/HeroSliderCreative';
import PortfolioMasonry from '../../components/Portfolio/PortfolioMasonry';
import FooterOne from '../../components/Footer/FooterOne';
import dataNav from '../../data/Navbar/nav-gallery-data.json';

const CreativePortfolio = () => {
    const home = useRef();
    const gallery = useRef();

    const scrollToSection = (e, content) => {
        e.preventDefault();
        switch (content) {
            case 'home':
                home.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'gallery':
                gallery.current.scrollIntoView({ behavior: 'smooth' });
                break;
            default:
        }
    };

    return (
        <Loader>
            <HeaderFive data={dataNav} scrollToSection={scrollToSection} />
            <HeroSliderCreative data={dataCreative} ref={home} />
            <PortfolioMasonry
                filter='true'
                columns='3'
                data={dataPortfolio}
                type='masonry'
                ref={gallery}
            />
            <FooterOne />
        </Loader>
    );
};

export default CreativePortfolio;
