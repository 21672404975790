import React, { forwardRef } from 'react';
import logoFooter from '../../assets/images/logo-footer.png';
import FooterCopyright from './FooterCopyright';

const FooterOne = forwardRef((_, ref) => (
    <>
        <footer className='footer' id='footer-fixed' ref={ref}>
            <div className='footer-main'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-6 col-md-4'>
                            <div className='widget widget-text'>
                                <div className='logo logo-footer'>
                                    <a href={`${process.env.PUBLIC_URL}/`}>
                                        <img
                                            className='logo logo-display'
                                            src={logoFooter}
                                            alt=''
                                        />
                                    </a>
                                </div>
                                <p>
                                    Somos una constructora vangurdista
                                    utilizando materiales, procesos
                                    constructivos y herramientas de innovación
                                    para el desarrollo y mantenimiento con
                                    proyectos de clientes en distintas ramas del
                                    mercado.
                                </p>
                            </div>
                        </div>
                        <div className='col-sm-6 col-md-3'>
                            {/* <div className='widget widget-links'>
                                <h5 className='widget-title'>Useful Links</h5>
                                <ul>
                                    <li>
                                        <a href={process.env.PUBLIC_URL}>
                                            About Us
                                        </a>
                                    </li>
                                    <li>
                                        <a href={process.env.PUBLIC_URL}>
                                            Contact Us
                                        </a>
                                    </li>
                                    <li>
                                        <a href={process.env.PUBLIC_URL}>
                                            Our Services
                                        </a>
                                    </li>
                                    <li>
                                        <a href={process.env.PUBLIC_URL}>
                                            Terms &amp; Conditions
                                        </a>
                                    </li>
                                    <li>
                                        <a href={process.env.PUBLIC_URL}>
                                            Careers
                                        </a>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                        <div className='col-sm-6 col-md-4'>
                            <div className='widget widget-text widget-links'>
                                <h5 className='widget-title'>¡Contáctanos!</h5>
                                <ul>
                                    <li>
                                        <i className='icofont icofont-google-map'></i>
                                        <a href={process.env.PUBLIC_URL}>
                                            Calle General Bernardo Reyes, 83104
                                            Hermosillo SON, México
                                        </a>
                                    </li>
                                    <li>
                                        <i className='icofont icofont-iphone'></i>
                                        <a href='tel:441632960290'>
                                            +52 1 662 471 1982
                                        </a>
                                    </li>
                                    <li>
                                        <i className='icofont icofont-mail'></i>
                                        <a href='mailto:helloscoda@gmail.com'>
                                            jfigueroa@fccisa.com
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterCopyright />
        </footer>
        <div className='footer-height' style={{ height: '463px' }}></div>
    </>
));

export default FooterOne;
